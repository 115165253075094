import { useEffect, useState } from 'react'
import { ethers, utils } from "ethers"

import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider"
import WalletLink from "walletlink"
import BlockWalletLogo from "./blockwallet.svg"
import './App.css';

const contractAddress = process.env.REACT_APP_CONTRACT
const contractABI = require('./contractABI.json')
const donations = ["0.05", "0.1", "0.3", "0.5", "1", "3", "5", "10"]

const blockWalletProvider = {
    id: "injected",
    name: "BlockWallet",
    logo: BlockWalletLogo,
    type: "injected",
    check: "isBlockWallet"
}

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: process.env.REACT_APP_INFURA
        }
    },
    walletlink: {
        package: WalletLink,
        options: {
            appName: "Ukraine NFT",
            infuraId: process.env.REACT_APP_INFURA,
            chainId: process.env.REACT_APP_CHAIN_ID,
        }
    },
    "custom-blockWallet": {
        display: {
            logo: BlockWalletLogo,
            name: "BlockWallet",
            description: "Connect to BlockWallet",
        },
        package: blockWalletProvider,
        connector: async () => {
            let provider = null

            if (window.ethereum) {
                provider = window.ethereum

                if (!provider.isBlockWallet) {
                    throw new Error("Not a BlockWallet")
                }

                try {
                    await provider.request({ method: "eth_requestAccounts" })
                } catch (error) {
                    throw new Error("User Rejected")
                }
            } else {
                throw new Error("BlockWallet not found")
            }

            return provider
        }
    },
}

const web3Modal = new Web3Modal({
    network: process.env.REACT_APP_NETWORK,
    cacheProvider: true,
    providerOptions
})

let provider = null
let signer = null

function App() {
    let [isConnected, setIsConnected] = useState(false)
    let [isDonating, setIsDonating] = useState(null)
    let [error, setError] = useState(false)
    let [connectedAddress, setConnectedAddress] = useState(false)
    let [selectedDonation, setSelectedDonation] = useState(1)
    let [txHash, setTxHash] = useState(false)
    let [txConfirmed, setTxConfirmed] = useState(false)
    let [nftId, setNftId] = useState(false)

    useEffect(() => {
        async function reconnect() {
            if (web3Modal.cachedProvider) {
                let instance = await web3Modal.connect()
                provider = new ethers.providers.Web3Provider(instance)
                signer = provider.getSigner()
                let address = await signer.getAddress()
                setConnectedAddress(address)
                setIsConnected(true)
            }
        }

        reconnect()
    }, [])

    const connect = async () => {
        let instance = await web3Modal.connect()
        provider = new ethers.providers.Web3Provider(instance)
        signer = provider.getSigner()
        let address = await signer.getAddress()
        setConnectedAddress(address)

        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
        });

        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => { // { chainId: number }
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => { // { code: number; message: string }
        });

        setIsConnected(true)
    }

    const mint = async () => {
        let contract = new ethers.Contract(contractAddress, contractABI, signer)

        try {
            setError(false)
            setIsDonating(true)
            const tx = await contract.donate(selectedDonation, { value: utils.parseEther(donations[selectedDonation - 1]) })
            setIsDonating(false)
            setTxHash(tx.hash)
            // tx.hash
            const receipt = await tx.wait()

            const nftIdHex = receipt.logs[0].topics[3]
            setNftId(parseInt(nftIdHex, 16))
            // ${process.env.REACT_APP_NFT_EXPLORER}{process.env.REACT_APP_CONTRACT}/${nftId}
            setTxConfirmed(receipt.confirmations > 0)
        } catch (error) {
            switch (error.code) {
                case 'INSUFFICIENT_FUNDS':
                    setError('Insufficient funds')
                    break;
                default:
                    setError(error.message)
            }
            setIsDonating(false)
            console.error("😥 Something went wrong: " + error.message)
        }
    }

    const changeSelect = (e) => {
        const value = donations[e.target.value - 1];
        setSelectedDonation(e.target.value)

        document.querySelector('[hero-button]').setAttribute('href', value);
        document.querySelectorAll('[hero-img]').forEach(elmn => {
            elmn.classList.remove('active');
        });
        document.querySelector(`[hero-img="${value}"]`).classList.add('active');
    }

    function dropDowna() {
        document.querySelector('.faq__trigger.faq-1').parentElement.classList.toggle('active');
    };
    function dropDownb() {
        document.querySelector('.faq__trigger.faq-2').parentElement.classList.toggle('active');
    };
    function dropDownc() {
        document.querySelector('.faq__trigger.faq-3').parentElement.classList.toggle('active');
    };

    return (
        <div className="App">
            <div className="blue-line">
                <div className="blue-line__wrapper">
                    <div className="text-1--bold">All proceedings go to official Ukraine ETH wallet via smart contract. <a href="https://etherscan.io/address/0xc8550a172b99e3e7628607904a70e8ec098d5494#code" target="_blank"
                        className="text-1--blod__link">View smart contract »</a>
                    </div>
                </div>
            </div>
            <div className="hero wf-section">
                <div className="hero__wrapper">
                    <div className="hero__nav_bar">
                        <span className="nav_bar__logo w-inline-block"><img src="./images/ukr-icon.svg" loading="lazy"
                            alt="" className="nav_bar__logo-icon" />
                            <div id="w-node-_4cde4dc1-0de1-d070-5747-f67e1e80d9c5-5d3710b2" className="nav_bar__logo-text">
                                <h2>WeSupportUkraineNFT</h2>
                            </div>
                        </span>
                        <div className="nav_bar__navigation">
                            <a href="#smart-contract" className="nav_bar__navigation-link w-inline-block">
                                <div className="text-1">Smart contract</div>
                            </a>
                            <a href="#faq" className="nav_bar__navigation-link w-inline-block">
                                <div className="text-1">FAQ</div>
                            </a>
                            <div id="w-node-dc8583da-68bd-00ad-46d3-0f769924e8fa-5d3710b2" className="nav_bar__button-wrap">
                                {!isConnected ? (
                                    <span hero-button="1" id="w-node-_7f0ab498-c012-884b-642a-8b6c53597dd9-5d3710b2"
                                        className="button w-button" onClick={connect}>Connect Wallet</span>

                                ) : (
                                    <div id="w-node-b95611f7-81ba-af10-25ca-f2a3406f2eeb-5d3710b2"
                                        className="nav_bar__connected">
                                        <div className="text-1">Wallet Connected</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="hero__content">
                        <div id="w-node-_028b407b-2fad-4a11-c56a-ea4f3abe867b-5d3710b2" className="hero__content-headline">
                            <div className="hero__content-heading">
                                <h1 id="w-node-e3d06039-89be-7882-82f3-d7dec20f8821-5d3710b2" className="h1--w600">Show your
                                    support with NFT badge</h1>
                                <div id="w-node-abebb43d-c449-1040-c08e-1e7600f78956-5d3710b2" className="text-1">Get an NFT
                                    badge for your donation to Ukraine’s official ETH wallet.
                                </div>
                            </div>
                            {!isConnected ? (
                                <div id="w-node-_2b4df7d9-9baf-32b2-7561-128236d806e1-5d3710b2" className="stage-1">
                                    <span hero-button="1" className="button w-button" onClick={connect}>Connect Wallet</span>
                                </div>
                            ) : (
                                <>
                                    {(!isDonating && !txHash && !txConfirmed) && (
                                        <div id="w-node-_041abb59-d80b-d02b-a4a5-2f4551b47023-5d3710b2" className="stage-2">
                                            <div id="w-node-f8cf9262-2a16-9dae-e8ce-54827adcfda0-5d3710b2"
                                                className="hero__content-donate_button">
                                                <div id="w-node-_9230be17-8995-f212-733e-62f60da81ed5-5d3710b2"
                                                    className="hero__content-dropdown w-embed">
                                                    <select name="filter" id="sel-sum" className="select-filter" value={selectedDonation} onChange={changeSelect}>
                                                        {donations.map((d, i) => (
                                                            <option key={d} value={i + 1}>{d} ETH</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <span hero-button="1" id="w-node-ee6089a2-8f03-1dca-7678-8ca55b98d717-5d3710b2"
                                                    className="button w-button" onClick={mint}>Donate &amp; Mint NFT</span>
                                            </div>
                                        </div>
                                    )}
                                    {(isDonating || txHash || txConfirmed) && (
                                        <div id="w-node-_231a5df7-7597-7122-aa8a-da609e81d2f8-5d3710b2" className="stage-3">
                                            <div className="transaction__status">
                                                <div id="w-node-ad15c405-eaba-72e3-d496-e9e0d59f72e0-5d3710b2"
                                                    className="transaction__status-left">
                                                    {!txConfirmed && (
                                                        <div className="loading"><img src="./images/loading-svg.svg" loading="lazy"
                                                            id="w-node-bbe7563e-3b42-69b7-6c73-ac0559167c0f-5d3710b2"
                                                            alt="" className="loading-icon" />
                                                            <div id="w-node-aa509700-9c23-942e-931b-8a3afc15159d-5d3710b2"
                                                                className="text-1">{txHash ? 'Transaction minting' : 'Confirm transaction'}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {txConfirmed && (
                                                        <div id="w-node-ca8363ed-130c-d208-4858-74f1f046f9c2-5d3710b2"
                                                            className="confirmed"><img src="./images/check-icon.png" loading="lazy"
                                                                id="w-node-c18c113f-4273-400e-50c2-269d64b01e1f-5d3710b2"
                                                                alt="" className="check-icon" />
                                                            <div id="w-node-_206fe2da-304f-2daa-8396-56a2ba10d0e6-5d3710b2"
                                                                className="text-1">Transaction confirmed
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div id="w-node-a933c834-91ad-45ea-34bb-7fcacc68e493-5d3710b2"
                                                    className="transaction__status-right">
                                                    {(txHash && !txConfirmed) && (
                                                        <a
                                                            id="w-node-_8758af26-78af-42b1-f9f6-4b88e021ad26-5d3710b2"
                                                            target="_blank"
                                                            href={`${process.env.REACT_APP_EXPLORER}tx/${txHash}`}
                                                            className="etherscan w-inline-block"
                                                        >
                                                            <div className="text-1">View on Etherscan</div>
                                                        </a>
                                                    )}
                                                    {txConfirmed && (
                                                        <a
                                                            id="w-node-_1f55df55-d3a1-be30-4b5b-6f073bfe2c8d-5d3710b2"
                                                            target="_blank"
                                                            href={`${process.env.REACT_APP_NFT_EXPLORER}${process.env.REACT_APP_CONTRACT}/${nftId}`}
                                                            className="opensea w-inline-block"
                                                        >
                                                            <div className="text-1">View on Opensea</div>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {error && (
                                        <div className='text-1 error'>{error}</div>
                                    )}
                                </>
                            )}

                            <div id="w-node-_8d4da02b-ef01-9dc6-f3f4-d955be996b9f-5d3710b2" className="hero__content-info">

                                {/*
                                <div className="text-1--normal"><strong>16 NFTs</strong> minted donating <strong>3.15
                                    ETH</strong> so far.
                                </div>
                                */}
                            </div>
                        </div>
                        <div id="w-node-_503ad6ae-8440-473d-d12b-7a23c701350f-5d3710b2" className="hero__img"><img hero-img="0.05"
                            src="./images/0.05.png" loading="lazy" alt=""
                            className="hero__img-itm active" /><img hero-img="0.1" src="images/0.1.png" loading="lazy" alt=""
                                className="hero__img-itm" /><img hero-img="0.3" src="./images/0.3.png"
                                    loading="lazy"
                                    alt=""
                                    className="hero__img-itm" /><img hero-img="0.5"
                                        src="./images/0.5.png" loading="lazy" alt="" className="hero__img-itm" /><img hero-img="1"
                                            src="./images/1.png" loading="lazy" alt="" className="hero__img-itm" /><img hero-img="3"
                                                src="./images/3.png" loading="lazy" alt="" className="hero__img-itm" /><img hero-img="5"
                                                    src="./images/5.png" loading="lazy" alt="" className="hero__img-itm" /><img hero-img="10"
                                                        src="./images/10.png" loading="lazy" alt="" className="hero__img-itm" /></div>
                    </div>
                </div>
            </div>
            <div className="contract wf-section">
                <div className="contract__wrapper" id="smart-contract">
                    <div id="w-node-_473edf9b-6c40-8f02-6e9f-8f1bbf9ef8ce-5d3710b2" className="contract__heading">
                        <h1 id="w-node-c89d834b-3171-1639-bf74-c3c9c42c44c1-5d3710b2">All proceedings go to official Ukraine ETH wallet via smart contract.</h1>
                        <div className="contract__para">
                            <p className="text-1">We developed a smart contract so that every proceeding would go straight to <a target="_blank" className="white-link" href="https://twitter.com/Ukraine/status/1497594592438497282">Ukraine’s official ETH wallet</a> without ever reaching any other wallets. In order not to waste ETH for gas fees, donations from smart contract are withdrawn once a day.</p>
                        </div>
                    </div>
                    <img src="./images/code-snipet.png" loading="lazy" sizes="(max-width: 991px) 100vw, 56vw"
                        id="w-node-_3ec5b348-b5b1-9d00-95bd-0e1b1c790541-5d3710b2"
                        srcSet="./images/code-snipet-p-500.png 500w, ./images/code-snipet-p-800.png 800w, ./images/code-snipet.png 1600w"
                        alt="" className="contract__snippet" />
                    <div id="w-node-_97b319d3-d96c-bdd7-7048-f96c58a5f8ee-5d3710b2" className="contract__supported">
                        <a target="_blank" id="w-node-_60e3fd9e-90f7-6708-2872-a161ad7cb473-5d3710b2" href="https://etherscan.io/address/0xc8550a172b99e3e7628607904a70e8ec098d5494#code"
                            className="contract__read_more w-inline-block">
                            <div className="text-1--bold">View full smart contract »</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="faq wf-section">
                <div className="faq__wrapper" id="faq">
                    <h1 id="w-node-_943e2db1-28ad-0e5a-7d99-73b1ec2186d4-5d3710b2">Frequently Asked Questions</h1>
                    <div id="w-node-ce5404f3-3197-8f96-cb33-677d70993b60-5d3710b2" className="faq__list">
                        <div className="faq__item">
                            <div className="faq__trigger faq-1" onClick={dropDowna}>
                                <div className="subheading-1">What’s the supply of this NFT?</div>
                                <img src="./images/faq-arrow.svg" loading="lazy" alt="" className="faq__arrow" />
                            </div>
                            <div className="faq__dropdown">
                                <div className="faq__content">
                                    <p className="text-1">We believe that every donation deserves an NFT so the supply is unlimited. It’s created to publicly show your support, not to trade it for profits.</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__item">
                            <div className="faq__trigger faq-2" onClick={dropDownb}>
                                <div className="subheading-1">How can I be sure that donation reaches Ukraine?</div>
                                <img src="images/faq-arrow.svg" loading="lazy" alt="" className="faq__arrow" />
                            </div>
                            <div className="faq__dropdown">
                                <div className="faq__content">
                                    <p className="text-1">The Ethereum smart contract is written in a way that every proceeding would go straight to Ukraine’s official ETH wallet without ever reaching any other wallets. You can view the smart contract <a className="black-link" href="https://etherscan.io/address/0xc8550a172b99e3e7628607904a70e8ec098d5494#code" target="_blank">here</a>.</p>
                                </div>
                            </div>
                        </div>
                        <div className="faq__item">
                            <div className="faq__trigger faq-3" onClick={dropDownc}>
                                <div className="subheading-1">Who’s behind this NFT?</div>
                                <img src="images/faq-arrow.svg" loading="lazy" alt="" className="faq__arrow" />
                            </div>
                            <div className="faq__dropdown dropdown--big">
                                <div className="faq__content">
                                    <p className="text-1">Just some regular people who are against Russia’s invasion to Ukraine and try to help Ukrainian heroes.<br /><br />
                                        NFT | Design | Initiative - <a className="black-link" href="https://twitter.com/malisauskasLT" target="_blank">Erikas Mališauskas | malisauskas.eth</a><br />
                                        Smart contract development - <a className="black-link" href="https://github.com/zekronium" target="_blank">@zekronium</a><br />
                                        Infrastructure development - <a className="black-link" href="https://twitter.com/lekevicius" target="_blank">Jonas Lekevičius | jonas.eth</a><br />
                                        Front-end development - <a className="black-link" href="https://www.linkedin.com/in/matas-dambrauskas/" target="_blank">Matas Dambrauskas</a><br />
                                        Front-end development | Deployment - <a className="black-link" href="https://github.com/milasevicius" target="_blank">Rokas Milaševičius</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="twitter wf-section">
                <div className="twitter__wrapper">
                    <div id="w-node-_69b86d59-9eda-6d65-1988-eb93c05cd388-5d3710b2" className="twitter__heading">
                        <h1 id="w-node-_8854e25a-f3b9-28e9-1874-55a11b57950f-5d3710b2">#WeSupportUkraineNFT</h1>
                    </div>
                    <div id="w-node-_7e0641d5-1428-2c97-c4a8-699ad09f3ab0-5d3710b2"
                        className="twitter__embed w-embed w-script">
                        <blockquote className="twitter-tweet">
                            <p lang="en" dir="ltr">3/ Our Lithuanian🇱🇹 friends just started an initiative to mint an <a href="https://twitter.com/hashtag/NFT?src=hash&amp;ref_src=twsrc%5Etfw">#NFT</a> for everyone who&#39;s donating to Ukraine&#39;s official <a href="https://twitter.com/hashtag/ETH?src=hash&amp;ref_src=twsrc%5Etfw">#ETH</a> wallet. <a href="https://twitter.com/hashtag/WeSupportUkraineNFT?src=hash&amp;ref_src=twsrc%5Etfw">#WeSupportUkraineNFT</a> <br /><br />Find more information here:<a href="https://t.co/R2GRhuAiuH">https://t.co/R2GRhuAiuH</a></p>&mdash; Syntropy (@Syntropynet) <a href="https://twitter.com/Syntropynet/status/1499408795075760130?ref_src=twsrc%5Etfw">March 3, 2022</a>
                        </blockquote>
                    </div>
                    <div id="w-node-_4f84f35b-1195-9407-adc8-41c48b262066-5d3710b2"
                        className="twitter__embed w-embed w-script">
                        <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Great initiative from <a href="https://twitter.com/hashtag/WeSupportUkraineNFT?src=hash&amp;ref_src=twsrc%5Etfw">#WeSupportUkraineNFT</a>, which gets you a special NFT for every donation to Ukraine&#39;s official ETH wallet.<br/><br/>We integrated <a href="https://twitter.com/hashtag/BlockWallet?src=hash&amp;ref_src=twsrc%5Etfw">#BlockWallet</a> so you can donate privately.<a href="https://twitter.com/hashtag/CryptoForHumanity?src=hash&amp;ref_src=twsrc%5Etfw">#CryptoForHumanity</a><a href="https://t.co/24Ts6nQxxa">https://t.co/24Ts6nQxxa</a></p>&mdash; BlockWallet 🔲 (@GetBlockWallet) <a href="https://twitter.com/GetBlockWallet/status/1499793528968339456?ref_src=twsrc%5Etfw">March 4, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default App;
